import { lazy } from 'react';
const fabRej = lazy(() => import('./fabRej'));

const fabRejRoutes = [
  {
    path: '/fabRej',
    component: fabRej
  }
];

export default fabRejRoutes;
import { lazy } from 'react';
const verOutDrillDown = lazy(() => import('./verOutDrillDown'));

const verOutDrillDownRoutes = [
  {
    path: '/verOutDrillDown',
    component: verOutDrillDown
  }
];

export default verOutDrillDownRoutes;

import { lazy } from 'react';
const orderFilesInquiry = lazy(() => import('./orderFilesInquiry'));

const orderFilesInquiryRoutes = [
  {
    path: '/orderfilesinquiry',
    component: orderFilesInquiry
  }
];

export default orderFilesInquiryRoutes;

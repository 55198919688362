import { lazy } from 'react';
const freightCharges = lazy(() => import('./freightCharges'));

const freightChargesRoutes = [
  {
    path: '/freightCharges',
    component: freightCharges
  }
];

export default freightChargesRoutes;

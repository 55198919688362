import { lazy } from 'react';
const floorDashboard = lazy(() => import('./floorDashboard'));

const floorDashboardRoutes = [
  {
    path: '/floorDashboard',
    component: floorDashboard
  }
];

export default floorDashboardRoutes;
import { lazy } from 'react';
const dashboardLocalSale = lazy(() => import('./dashboardLocalSale'));

const dashboardLocalSaleRoutes = [
  {
    path: '/dashboardLocalSale',
    component: dashboardLocalSale
  }
];

export default dashboardLocalSaleRoutes;

import { lazy } from 'react';
const labBom = lazy(() => import('./labBom'));

const labBomRoutes = [
  {
    path: '/labbom',
    component: labBom
  }
];

export default labBomRoutes;
import { lazy } from "react";
const compoundIssue = lazy(() => import("./compoundIssue"));

const compoundIssueRoutes = [
    {
        path: "/cmpIssue",
        component: compoundIssue
    }
];

export default compoundIssueRoutes;
import { lazy } from 'react';
const poinquiry = lazy(() => import('./poinquiry'));

const poinquiryRoutes = [
  {
    path: '/poinquiry',
    component: poinquiry
  }
];

export default poinquiryRoutes;

import { lazy } from 'react';
const horplan = lazy(() => import('./horplan'));

const horplanRoutes = [
  {
    path: '/horplan',
    component: horplan
  }
];

export default horplanRoutes;
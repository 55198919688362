import { lazy } from 'react';
const horplanInq = lazy(() => import('./horplanInq'));

const horplanInqRoutes = [
  {
    path: '/horplanInq',
    component: horplanInq
  }
];

export default horplanInqRoutes;

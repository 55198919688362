import { lazy } from 'react';

const freightChargeApp = lazy(() => import('./freightChargeApp'));

const freightChargeAppRoutes = [
  {
    path: '/freightChargeApp',
    component: freightChargeApp
  }
];

export default freightChargeAppRoutes;